//import React from "react";
import { Container } from "react-bootstrap"; // Row, Col, 

///*"bdadmin@kriyamed.com"*/ 
//content-container
function MDSAPCertified() {
  return (
    <div style={{ backgroundColor: "#ffffff" }}>
      <Container className="mobile-padding position-relative ">
        <div className="foo-section" style={{ padding: "80px 0px" }}>
          <div className="foo-section-item">
            <div><p style={{ textTransform: "uppercase" }}>MDSAP _ KRIYA MEDICAL TECHNOLOGIES</p></div>
            <div><h1 className="sub-title">Kriya Medical Technologies Achieves Landmark MDSAP Certification</h1></div>
            <div className="technologies-align-center">
              <p>Kriya Medical Technologies is excited to share that we have achieved a significant milestone in our journey towards excellence: we have been awarded the prestigious Medical Device Single Audit Program (MDSAP) certification.</p>
              <p>This achievement positions us as the first and only medical device company in India to receive this certification for our blood collection tubes, insulin delivery devices, and molecular diagnostic kits.</p>
            </div>
          </div>
          <div className="foo-section-item">
            <img src="/images/canva/13.png" alt="hand" width="400px" height="auto" />
          </div>
        </div>
        <div>
          <h1 className="sub-title" style={{ color: "#914082" }}>A Milestone Achievement</h1>
          <p>The MDSAP certification, granted by leading regulatory authorities from the United States (FDA), Canada (Health Canada), Brazil (ANVISA), Japan (PMDA), and Australia (TGA), underscores Kriya Medical Technologies’ commitment to adhering to the highest international standards of quality and regulatory compliance. This certification allows us to streamline the regulatory process through a single audit, meeting the requirements of multiple countries with enhanced efficiency.</p>
          <h1 className="sub-title" style={{ color: "#914082" }}>Why This Matters</h1>
          <p>Kriya Medical Technologies is now recognized for meeting stringent quality and safety standards across a broad spectrum of medical devices. Our blood collection tubes, insulin delivery devices, and molecular diagnostic kits have all been validated under this rigorous certification process. This achievement not only reinforces our position as a leader in medical device innovation but also ensures that our products meet the most demanding global standards.</p>
          <h1 className="sub-title" style={{ color: "#914082" }}>CEO’s Statement</h1>
          <p>Anu Moturi, CEO and Founder of Kriya Medical Technologies expressed her enthusiasm about this accomplishment: “Receiving the MDSAP certification is a monumental milestone for Kriya Medical Technologies. This certification not only reflects our relentless pursuit of excellence and adherence to the highest quality standards but also enhances our ability to serve healthcare providers and patients globally. We are committed to developing innovative solutions that address critical healthcare needs, and this certification reinforces our position as a trusted leader in the medical device industry. I am incredibly proud of our team’s dedication and hard work in achieving this recognition.”</p>
          <h1 className="sub-title" style={{ color: "#914082" }}>Looking Ahead</h1>
          <p>This landmark achievement is a testament to the hard work and dedication of our entire team. It positions us to better serve our global customers and partners, ensuring that they have access to reliable, high-quality medical devices. We continue to strive for excellence and remain committed to advancing healthcare through innovation.
            Stay connected with us for more updates and exciting developments as we continue to set new standards in the medical device industry.</p>
        </div>
      </Container>
    </div>
  );
}
//
export default MDSAPCertified;
