//import React from "react";
import { Container } from "react-bootstrap"; // Row, Col, 

///*"bdadmin@kriyamed.com"*/ 
//content-container
function OurProducts() {
  return (
    <div>
      <div style={{ backgroundColor: "#914082" }}>
        <Container className="mobile-padding position-relative " style={{ height: "148px" }}>
          <div className="text-center-aligned" style={{ color: "#ffffff", paddingTop: "50px" }}>
            <h1 className="title">Our Products</h1>
          </div>
        </Container>
      </div>
      <div style={{ backgroundColor: "#ffffff" }}>
        <Container className="home-product">
          <div className="home-product-holder">
            <div className="home-product-molecule" style={{}}>
              <div className="home-product-molecule-content">
                <div className="px-5" style={{ color: "#914082", paddingTop: "40px" }}>
                  <h1 className="sub-title" >Molecular Diagnostics</h1>
                </div>
                <div className="px-5">
                  <p>Our breakthrough technologies and manufacturing capabilities offer an array of next-generation molecular diagnostic solutions which include nucleic acid testing and RT-PCR assays for diagnosing a wide range of infectious diseases.</p>
                </div>
                <div className="px-5">
                  <div className="banner-button" onClick={() => { window.location.href = "/products/molecular/" }}><div>Know More</div></div>
                </div>
              </div>
              <div className="home-product-molecule-image">
                <img alt="content" src="/images/canva/3.png" width="400px" height="auto" />
              </div>
            </div>
            <div className="home-product-split">
              <div className="p-5  home-product-bio-sample" >
                <div className="home-product-bio-sample-content">
                  <div>
                    <div style={{ color: "#914082" }}><h1 className="sub-title">Bio-Sample Collection</h1></div>
                    <p></p>
                    <p>Kriya has an innovative portfolio of biosample collection and preparation products. Manufactured with state-of-the-art technology</p>
                    <div className="">
                      <div className="banner-button" onClick={() => { window.location.href = "/products/biosample/" }}><div>Know More</div></div>
                    </div>
                  </div>
                  <div style={{ display: "flex", alignContent: "center" }}>
                    <img src="/images/canva/4.png" alt="" width="120px" height="120px" />
                  </div>
                </div>
              </div>
              <div className="p-5 home-product-diabetes" >
                <div className="home-product-bio-sample-content">
                  <div>
                    <div style={{ color: "#914082" }}><h1 className="sub-title">Diabetes Care</h1></div>
                    <p></p>
                    <p>Kriya offers advanced care solutions for the complete management of diabetes</p>
                    <div style={{ height: "20px" }} />
                    <div className="">
                      <div className="banner-button" onClick={() => { window.location.href = "/products/diabetes/" }}><div>Know More</div></div>
                    </div>
                  </div>
                  <div style={{ display: "flex", alignContent: "center" }}>
                    <img src="/images/canva/5.png" alt="" width="120px" height="120px" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}
//
export default OurProducts;
