//import React from "react";
import { Container } from "react-bootstrap"; // Row, Col, 

///*"bdadmin@kriyamed.com"*/ 
//content-container
function Services() {
  const scrollCert = () => {
    const section = document.querySelector('.scroll-certification');
    section?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const scrollAR = () => {
    const section = document.querySelector('.scroll-ar');
    section?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const scrollDigital = () => {
    const section = document.querySelector('.scroll-digital');
    section?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  return (
    <div style={{ backgroundColor: "#efefef" }}>
      <Container className="mobile-padding position-relative ">
        <div className="service-card">
          <div className="service-layer">
            <div style={{ display: "flex", justifyContent: "center", paddingTop: "120px" }}><h1 className="banner-title" style={{ color: "#914082" }}>Services We Offer</h1></div>
            <div className="service-holder">
              <div className="service-card-item" onClick={() => { scrollCert(); }}>
                <div style={{ display: "flex", justifyContent: "center" }}><img src="/images/canva/17.png" alt="" width="50px" height="auto" /></div>
                <div className="text-center-aligned" style={{ display: "flex", justifyContent: "center" }}><h1 className="content-title">Certifications</h1></div>
                <div><p className="text-center-aligned">Government-supported certifications ensure compliance and excellence in healthcare standards.</p></div>
                <div style={{ display: "flex", justifyContent: "center" }}><img src="/images/canva/plus.png" alt="" width="20px" height="auto" /></div>
              </div>
              <div className="service-card-item" onClick={() => { scrollAR(); }}>
                <div style={{ display: "flex", justifyContent: "center" }}><img src="/images/canva/18.png" alt="" width="50px" height="auto" /></div>
                <div className="text-center-aligned" style={{ display: "flex", justifyContent: "center" }}><h1 className="content-title">Training Modules</h1></div>
                <div className="text-center-aligned"><p>One-click Learn modules with access to learning with AR modules designed specifically for phlebotomists & Lab technicians</p></div>
                <div style={{ display: "flex", justifyContent: "center" }}><img src="/images/canva/plus.png" alt="" width="20px" height="auto" /></div>
              </div>
              <div className="service-card-item" onClick={() => { scrollDigital(); }}>
                <div style={{ display: "flex", justifyContent: "center" }}><img src="/images/canva/19.png" alt="" width="50px" height="auto" /></div>
                <div className="text-center-aligned" style={{ display: "flex", justifyContent: "center" }}><h1 className="content-title">Digital Services for Labs</h1></div>
                <div><p className="text-center-aligned">Streamlining lab operations with advanced one-click digital solutions.</p></div>
                <div style={{ display: "flex", justifyContent: "center" }}><img src="/images/canva/plus.png" alt="" width="20px" height="auto" /></div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
//

function Certifications() {
  return (
    <div style={{ backgroundColor: "#ffffff" }}>
      <Container className="mobile-padding position-relative scroll-certification">
        <div className="service-card" style={{ backgroundColor: "#ffffff" }}>
          <div className="service-layer">
            <div style={{ display: "flex", justifyContent: "center", paddingTop: "120px" }}><h1 className="banner-title" style={{ color: "#914082" }}>Certifications</h1></div>
            <div className="service-holder">
              <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", gap: "20px" }}>
                <div style={{ display: "flex", justifyContent: "center" }}><img src="/images/canva/20.png" alt="" width="75px" height="auto" /></div>
                <div className="text-center-aligned"><h1 className="content-title">AR Modules for Phlebotomist</h1></div>
              </div>
              <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", gap: "20px" }}>
                <div style={{ display: "flex", justifyContent: "center" }}><img src="/images/canva/21.png" alt="" width="75px" height="auto" /></div>
                <div className="text-center-aligned"><h1 className="content-title" >Personalized and strategic approach</h1></div>
              </div>
              <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", gap: "20px" }}>
                <div style={{ display: "flex", justifyContent: "center" }}><img src="/images/canva/22.png" alt="" width="75px" height="auto" /></div>
                <div className="text-center-aligned"><h1 className="content-title">Excellent representation</h1></div>
              </div>
              <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", gap: "20px" }}>
                <div style={{ display: "flex", justifyContent: "center" }}><img src="/images/canva/23.png" alt="" width="75px" height="auto" /></div>
                <div className="text-center-aligned"><h1 className="content-title">Knowledgeable and experienced team</h1></div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-auto-sec">
          <div className="for-phel" style={{ flex: 1 }}>
            <div style={{ display: "flex" }}><h1 className="sub-title">For Phlebotomy</h1></div>
            <div style={{ display: "flex", textTransform: "uppercase", paddingTop: "40px" }}>Course Details</div>
          </div>
          <div style={{ flex: 1 }}>
            <img src="/images/canva/88.png" alt="" width="100%" height="auto" />
          </div>
        </div>
        <div className="flex-auto-sec">
          <div style={{ flex: 1 }}>
            <img src="/images/canva/89.png" alt="" width="100%" height="auto" />
          </div>
          <div style={{ flex: 1, padding: "60px" }}>
            <p>
              Kriya Medical Technologies offers a comprehensive certification course for phlebotomists, designed to provide both theoretical knowledge and practical skills.
            </p>
            <p>
              The course features the innovative "One Click Learn" Augmented reality-based modules, which enhances learning through immersive augmented reality experiences. Participants also benefit from live sessions led by industry experts, providing real-time interaction and feedback.
            </p>
            <p>
              Group sessions foster collaborative learning and peer support, while hands-on lab training ensures the practical application of skills.
            </p>
            <p>
              This multi-faceted approach ensures that phlebotomists gain a thorough understanding of best practices, error prevention, and advanced techniques in blood collection, preparing them for excellence in their professional roles.
            </p>
          </div>
        </div>
      </Container>
    </div>
  );
}


function ARTraining() {
  return (
    <div style={{ backgroundColor: "#914082", color: "#fff" }}>
      <Container className="scroll-ar">
        <div>
          <div className="text-center-aligned" style={{ display: "flex", justifyContent: "center", paddingTop: "120px", color: "#fff" }}>
            <h1 className="sub-title">AR-Based Training Modules for Phlebotomists</h1>
          </div>
          <div style={{ padding: "40px" }}>
            <p>"One Click Learn" training course is specifically designed for phlebotomists. The module employs both static and augmented reality (AR) components to enhance the learning experience. Each module is meticulously crafted to cover essential topics, from understanding the circulatory system to mastering the process of drawing blood and handling samples. The course also includes pre-session and post-session quizzes to ensure comprehension and accuracy. Additionally, the module incorporates soft skills training and advanced sample collection techniques, making it a holistic training program for modern phlebotomists.</p>
          </div>
        </div>
        <div className="training-holder">
          <div className="training-item">
            <div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img src="/images/canva/24.png" alt="" width="75px" height="auto" />
              </div>
              <div className="text-center-aligned" style={{ display: "flex", justifyContent: "center", paddingTop: "20px" }}>
                <p>Introduction to Circulatory system</p>
              </div>
            </div>
          </div>
          <div className="training-item">
            <div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img src="/images/canva/25.png" alt="" width="75px" height="auto" />
              </div>
              <div className="text-center-aligned" style={{ display: "flex", justifyContent: "center", paddingTop: "20px" }}>
                <p>Training to measure vitals</p>
              </div>
            </div>
          </div>
          <div className="training-item">
            <div>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img src="/images/canva/26.png" alt="" width="75px" height="auto" />
              </div>
              <div className="text-center-aligned" style={{ display: "flex", justifyContent: "center", paddingTop: "20px" }}>
                <p>Types of Blood collection system</p>
              </div>
            </div>
          </div>
          <div className="training-item">
            <div >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img src="/images/canva/27.png" alt="" width="75px" height="auto" />
              </div>
              <div className="text-center-aligned" style={{ display: "flex", justifyContent: "center", paddingTop: "20px" }}>
                <p>Process of drawing blood</p>
              </div>
            </div>
          </div>
          <div className="training-item">
            <div >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img src="/images/canva/28.png" alt="" width="75px" height="auto" />
              </div>
              <div className="text-center-aligned" style={{ display: "flex", justifyContent: "center", paddingTop: "20px" }}>
                <p>Difficult Phelbotomy</p>
              </div>
            </div>
          </div>
          <div className="training-item">
            <div >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img src="/images/canva/29.png" alt="" width="75px" height="auto" />
              </div>
              <div className="text-center-aligned" style={{ display: "flex", justifyContent: "center", paddingTop: "20px" }}>
                <p>Sample handling</p>
              </div>
            </div>
          </div>
          <div className="training-item">
            <div >
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img src="/images/canva/30.png" alt="" width="75px" height="auto" />
              </div>
              <div className="text-center-aligned" style={{ display: "flex", justifyContent: "center", paddingTop: "20px" }}>
                <p>Infection control</p>
              </div>
            </div>
          </div>
          <div className="training-item">
            <div style={{ backgroundColor: "#eeeef0", color: "#914082" }}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img src="/images/canva/31.png" alt="" width="75px" height="auto" />
              </div>
              <div className="text-center-aligned" style={{ display: "flex", justifyContent: "center", paddingTop: "20px" }}>
                <p>Other Bio Sample Collections</p>
              </div>
            </div>
          </div>
        </div>
        <div className="phlebo-back">
          <div className="service-holder">
            <div style={{ color: "#ffffff", flex: 1, backgroundColor: "#914082", padding: "40px" }}>
              <div>
                <div className="text-center-aligned"><h1 className="content-title" >Introduction to Phlebotomy</h1></div>
                <div className="text-center-aligned"><p>This module provides an introduction to the critical issues faced by phlebotomists, including the prevalence of lab errors and their potential consequences. </p></div>
              </div>
            </div>
            <div style={{ color: "#ffffff", flex: 1, backgroundColor: "#914082", padding: "40px" }}>
              <div>
                <div className="text-center-aligned"><h1 className="content-title" >Introduction to Circulatory System</h1></div>
                <div className="text-center-aligned"><p>This module delves into the anatomy and physiology of the circulatory system, focusing on its relevance to blood collection</p></div>
              </div>
            </div>
            <div style={{ color: "#ffffff", flex: 1, backgroundColor: "#914082", padding: "40px" }}>
              <div>
                <div className="text-center-aligned"><h1 className="content-title" >Types of Blood Collection Systems</h1></div>
                <div className="text-center-aligned"><p>This module provides an overview of different blood collection systems and their components</p></div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}


function DigitalProduct() {
  const scrollID = () => {
    const section = document.querySelector('.scroll-one-click-id');
    section?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };
  const scrollLearn = () => {
    const section = document.querySelector('.scroll-one-click-learn');
    section?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };
  const scrollCollect = () => {
    const section = document.querySelector('.scroll-one-click-collect');
    section?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };
  const scrollSafety = () => {
    const section = document.querySelector('.scroll-one-click-safety');
    section?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  return (
    <div >
      <div style={{ backgroundColor: "#914082", color: "#fff" }}>
        <Container className="scroll-digital">
          <div className="text-center-aligned" style={{ padding: "40px" }}><h1 className="sub-title">Digital Products & Services for Labs</h1></div>
        </Container>
      </div>
      <div>
        <Container>
          <div className="digital-prod-holder">
            <div style={{ flex: 1, display: "flex", justifyContent: "center", flexDirection: "column" }}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img src="/images/canva/67.png" alt="" width="100px" height="auto" />
              </div>
              <div className="one-click-button" onClick={() => { scrollCollect() }}>
                One - Click Collect
              </div>
            </div>
            <div style={{ flex: 1, display: "flex", justifyContent: "center", flexDirection: "column" }}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img src="/images/canva/68.png" alt="" width="100px" height="auto" />
              </div>
              <div className="one-click-button" onClick={() => { scrollID() }}>
                One - Click ID
              </div>
            </div>
            <div style={{ flex: 1, display: "flex", justifyContent: "center", flexDirection: "column" }}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img src="/images/canva/69.png" alt="" width="100px" height="auto" />
              </div>
              <div className="one-click-button" onClick={() => { scrollSafety() }}>
                One- Click Safety
              </div>
            </div>
            <div style={{ flex: 1, display: "flex", justifyContent: "center", flexDirection: "column" }}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <img src="/images/canva/70.png" alt="" width="100px" height="auto" />
              </div>
              <div className="one-click-button" onClick={() => { scrollLearn() }}>
                One - Click Learn
              </div>
            </div>
          </div>
          <div className="flex-auto-sec scroll-one-click-collect">
            <div style={{ flex: 1 }}>
              <div style={{ display: "flex", color: "#914082" }}>
                <img src="/images/canva/67.png" alt="" width="75px" height="auto" />
                <h1 className="sub-title">One-Click Collect</h1>
              </div>
              <div style={{ padding: "40px" }}>
                <p>One-Click Collect is a first-of-its-kind, fully integrated system that enables labs to take complete custody of patient samples — from collection and transportation to analysis and reporting. It ensures the accuracy of diagnostics and therapeutic decisions with proper specimen handling. It is a cloud-based platform which optimizes sample handling while also offering labs a SMART, Bluetooth-enabled cold chain transport system. This allows real-time temperature and location tracking.</p>
              </div>
              <div>
                <img src="/images/canva/92.png" alt="" width="100%" height="auto" />
              </div>
              <div style={{ height: "120px" }}></div>
            </div>
            <div style={{ flex: 1 }}>
              <img src="/images/canva/91.png" alt="" width="100%" height="auto" />
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center", padding: "40px" }}>
            <img src="/images/canva/93.png" alt="" width="100%" height="auto" />
          </div>
          <div className="flex-auto-sec scroll-one-click-learn">
            <div style={{ flex: 1 }}>
              <div style={{ display: "flex", color: "#914082" }}>
                <img src="/images/canva/70.png" alt="" width="75px" height="auto" />
                <h1 className="sub-title">One-Click Learn</h1>
              </div>
              <div style={{ padding: "40px" }}>
                <p>
                  One-Click Learn is a <b>first-of-its-kind augmented reality, digital learning platform</b> with expert-curated and <b>pre-analytical training modules for phlebotomists and technicians.</b> This platform facilitates an <b>interactive and immersive learning experience</b> through one-on-one, group remote, and live training sessions for technicians in labs and hospitals. It provides them with continuing education, which is key and critical to <b>minimizing pre-analytical errors</b> in sample collection - resulting in <b>greater accuracy of test results.</b>
                </p>
              </div>
              <div>
                <img src="/images/canva/95.png" alt="" width="100%" height="auto" />
              </div>
            </div>
            <div style={{ flex: 1 }}>
              <img src="/images/canva/94.png" alt="" width="100%" height="auto" />
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center", padding: "40px" }}>
            <img src="/images/canva/process.png" alt="" width="100%" height="auto" />
          </div>
          <div className="flex-auto-sec scroll-one-click-safety">
            <div style={{ flex: 1 }}>
              <div style={{ display: "flex", color: "#914082" }}>
                <img src="/images/canva/69.png" alt="" width="75px" height="auto" />
                <h1 className="sub-title">One-Click Safety</h1>
              </div>
              <div style={{ padding: "40px" }}>
                <p>
                  One-Click Safety is an end-to-end software solution integrated with our novel KRIVIDA Self-Collection Saliva Kit. It offers various SMART devices which ensure the health security of campuses and communities by enabling
                </p>
                <p></p>
                <p></p>
                <ul>
                  <li> Frequent testing and screening </li>
                  <li> Consistent reporting </li>
                  <li> Apt tracing </li>
                </ul>
              </div>
            </div>
            <div style={{ flex: 1 }}>
              <img src="/images/canva/96.png" alt="" width="100%" height="auto" />
            </div>
          </div>
          <div className="flex-auto-sec scroll-one-click-id">
            <div style={{ flex: 1 }}>
              <img src="/images/canva/97.png" alt="" width="100%" height="600px" />
            </div>
            <div style={{ flex: 1 }}>
              <div style={{ display: "flex", color: "#914082" }}>
                <img src="/images/canva/68.png" alt="" width="75px" height="auto" />
                <h1 className="sub-title">One-Click ID</h1>
              </div>
              <div style={{ padding: "40px" }}>
                <p>One-Click ID is an augmented reality-powered visualization platform that enables laboratories to identify, track, and monitor samples in the lab during the analytical phase. This platform offers interactive and immersive visualizations of current and live data, allowing Lab technicians will ID the specimen and retrieve real-time information.                </p>
                <p>They also learn the status of the tests, enabling labs to solve bottlenecks in real time and deliver a faster turnaround time for their patients.</p>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}
//Digital Products & Services for Labs

function BlogPapers() {
  return (
    <div>
      <div style={{ backgroundColor: "#914082", color: "#fff", paddingTop: "50px", paddingBottom: "50px" }}>
        <Container>
          <div>
            <div className="text-center-aligned"><h1 className="sub-title">Blogs & Published Papers</h1></div>
          </div>
          <div className="blog-holder">
            <div className="blog-holder-item">
              <div style={{ display: "flex", justifyContent: "center" }}><img src="/images/canva/114.png" alt="" width="100%" height="auto" /></div>
              <div><h1 className="content-title">MDSAP - Certification</h1></div>
              <div><p>Kriya Medical Technologies Achieves Landmark MDSAP Certification</p></div>
              <div style={{ height: "40px" }}></div>
              <div style={{ cursor: "pointer" }} onClick={() => { window.location.href = "/blog_certification"; }}>Read more &nbsp;&gt;</div>
            </div>
            <div className="blog-holder-item">
              <div style={{ display: "flex", justifyContent: "center" }}><img src="/images/canva/113.png" alt="" width="100%" height="auto" /></div>
              <div><h1 className="content-title">A curious case of contamination in RT-PCR!!!</h1></div>
              <div><p>observed an interesting finding in the graphs of negative control – a small non-sigmoidal signal, crossing the threshold for Internal Control or IC</p></div>
              <div style={{ height: "10px" }}></div>
              <div style={{ cursor: "pointer" }} onClick={() => { window.location.href = "/blog_rtpcr"; }}>Read more &nbsp;&gt;</div>
            </div>
            <div className="blog-holder-item">
              <div style={{ display: "flex", justifyContent: "center" }}><img src="/images/canva/112.png" alt="" width="100%" height="auto" /></div>
              <div><h1 className="content-title">Trivus - AMR</h1></div>
              <div><p>Combatting Antimicrobial Resistance with the Krivida™ Trivus Respiratory Panel RT-qPCR Kit</p></div>
              <div style={{ height: "40px" }}></div>
              <div style={{ cursor: "pointer" }} onClick={() => { window.location.href = "/blog_trivus"; }}>Read more &nbsp;&gt;</div>
            </div>
          </div>
        </Container>
      </div>
    </div>
  );
}

export default Services;
export { DigitalProduct, ARTraining, Certifications, BlogPapers };