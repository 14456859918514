import Services, { ARTraining, Certifications, DigitalProduct } from "../components/Service/Services";
import Footer from "./Footer";
import Navigation from "./Navigation";

function OurServices() {
  return (
    <div>
      <Navigation />
      <Services />
      <Certifications />
      <ARTraining />
      <DigitalProduct />
      <Footer contact={false} />
    </div>
  );
}

export default OurServices;