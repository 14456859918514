//import React from "react";
import { useEffect, useState } from "react";
import { Container } from "react-bootstrap"; // Row, Col, 

///*"bdadmin@kriyamed.com"*/ 
//content-container
function ProductExpand() {
  const [page, setPage] = useState("");
  const action = (name: string) => {
    if (page === name) {
      setPage("");
    } else {
      setPage(name);
    }
  };
  useEffect(() => {
    const section = document.querySelector('.scroll-prod-' + page);
    section?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, [page]);
  return (
    <div style={{ backgroundColor: "#914082" }}>
      <Container className="mobile-padding position-relative ">
        <div className="product-list">
          <div className="product-list-item" onClick={() => { action("trivus") }}>
            <div style={{ display: "flex", justifyContent: "center" }}><img src="/images/canva/pro_trivus.png" alt="" width="100%" height="auto" /></div>
            <div style={{ display: "flex", justifyContent: "center" }}><img src="/images/canva/plus.png" alt="" width="20px" height="auto" /></div>
          </div>
          <div className="product-list-item" onClick={() => { action("diabetes") }}>
            <div style={{ display: "flex", justifyContent: "center" }}><img src="/images/canva/pro_syringe.png" alt="" width="100%" height="auto" /></div>
            <div style={{ display: "flex", justifyContent: "center" }}><img src="/images/canva/plus.png" alt="" width="20px" height="auto" /></div>
          </div>
          <div className="product-list-item" onClick={() => { action("novus") }}>
            <div style={{ display: "flex", justifyContent: "center" }}><img src="/images/canva/pro_novus.png" alt="" width="100%" height="auto" /></div>
            <div style={{ display: "flex", justifyContent: "center" }}><img src="/images/canva/plus.png" alt="" width="20px" height="auto" /></div>
          </div>
          <div className="product-list-item" onClick={() => { action("biosample") }}>
            <div style={{ display: "flex", justifyContent: "center" }}><img src="/images/canva/pro_biosample.png" alt="" width="100%" height="auto" /></div>
            <div style={{ display: "flex", justifyContent: "center" }}><img src="/images/canva/plus.png" alt="" width="20px" height="auto" /></div>
          </div>
          <div className="product-list-item" onClick={() => { action("respi") }}>
            <div style={{ display: "flex", justifyContent: "center" }}><img src="/images/canva/pro_respi.png" alt="" width="100%" height="auto" /></div>
            <div style={{ display: "flex", justifyContent: "center" }}><img src="/images/canva/plus.png" alt="" width="20px" height="auto" /></div>
          </div>
          <div className="product-list-item" onClick={() => { action("urine") }}>
            <div style={{ display: "flex", justifyContent: "center" }}><img src="/images/canva/pro_urine.png" alt="" width="100%" height="auto" /></div>
            <div style={{ display: "flex", justifyContent: "center" }}><img src="/images/canva/plus.png" alt="" width="20px" height="auto" /></div>
          </div>
        </div>
        {page === "trivus" && (<ProductKrividaTrivus />)}
        {page === "novus" && (<ProductNovus />)}
        {page === "diabetes" && (<ProductDiabetes />)}
        {page === "biosample" && (<ProductBiosample />)}
        {page === "respi" && (<ProductRespi />)}
        {page === "urine" && (<ProductUrine />)}
      </Container>
    </div>
  );
}
//

function ProductKrividaTrivus() {
  return (
    <div className="mobile-padding position-relative scroll-prod-trivus" style={{ backgroundColor: "#914082" }}>
      <div className="kriya-product">
        <div className="px-md-5 kriya-product-content" style={{ color: "#ffffff" }}>
          <div>
            <div> <h1 className="content-title" >Revolutionize Respiratory Diagnosis with Krivida Trivus Respi Panel - Unparalleled Accuracy, Unmatched Efficiency</h1></div>
          </div>
          <div className="product-key-list">
            <ul>
              <li><div><img src="images/canva/tick-circle.png" alt="" width="30px" height="30px" />Simultaneous Detection of Major Viruses</div></li>
              <li><div><img src="images/canva/tick-circle.png" alt="" width="30px" height="30px" />Rapid Turnaround Time</div></li>
              <li><div><img src="images/canva/tick-circle.png" alt="" width="30px" height="30px" />Cost-Efficient Testing</div></li>
              <li><div><img src="images/canva/tick-circle.png" alt="" width="30px" height="30px" />ICMR Validated Assurance & MDSAP Certification</div></li>
            </ul>
          </div>
          <div>
            <div className="banner-button-large" onClick={() => { window.location.href = "/trivus" }}><div><img src="/images/canva/110.png" alt="" width="20px" height="auto" /> &nbsp;&nbsp;Order from Partners</div></div>
          </div>
        </div>
        <div className="kriya-product-image">
          <img src="/images/canva/100.png" alt="krivida trivus" width="100%" height="auto" />
        </div>
      </div>
      <div className="kriya-product-key-list">
        <div className="kriya-pro-split">
          <div><img src="/images/canva/79.png" alt="" width="90px" height="auto" /></div>
          <div className="content-description content-align-vcenter"><div>RSV, Influenza A & B, and SARS-CoV-2 are detected concurrently, providing a comprehensive view of the patient’s viral landscape</div></div>
        </div>
        <div className="kriya-pro-split">
          <div><img src="/images/canva/80.png" alt="" width="90px" height="auto" /></div>
          <div className="content-description content-align-vcenter"><div>Our kit boasts a rapid turnaround time of less than 30 mins, empowering healthcare professionals with timely and actionable results.</div></div>
        </div>
        <div className="kriya-pro-split">
          <div><img src="/images/canva/81.png" alt="" width="90px" height="auto" /></div>
          <div className="content-description content-align-vcenter"><div>Seamlessly integrate our kit into your existing diagnostic infrastructure, as it is compatible with all available RTPCR instruments.</div></div>
        </div>
        <div className="kriya-pro-split" style={{ backgroundColor: "#eeeef0" }} onClick={() => { window.location.href = "/trivus" }}>
          <div><div className="content-title hand-cursor" style={{ color: "#914082" }}>Learn more &nbsp;&nbsp; <img src="/images/canva/arrow.png" alt="" width="30px" height="auto" /></div></div>
        </div>
      </div>
    </div>
  );
}

function ProductNovus() {
  return (
    <div className="mobile-padding position-relative scroll-prod-novus" style={{ backgroundColor: "#914082" }}>
      <div className="kriya-product">
        <div className="px-md-5 kriya-product-content" style={{ color: "#ffffff" }}>
          <div>
            <div><h1 className="content-title" >KRIVIDA™ Novus RT PCR Kit: Omicron Detection </h1></div>
          </div>
          <div className="product-key-list">
            <ul>
              <li><div><img src="images/canva/tick-circle.png" alt="" width="30px" height="30px" />Detects Omicron variants with high precision. </div></li>
              <li><div><img src="images/canva/tick-circle.png" alt="" width="30px" height="30px" />Delivers results in under 60 minutes. </div></li>
              <li><div><img src="images/canva/tick-circle.png" alt="" width="30px" height="30px" />Ensures accurate detection even in low viral loads. </div></li>
              <li><div><img src="images/canva/tick-circle.png" alt="" width="30px" height="30px" />ICMR Validated Assurance & MDSAP Certification</div></li>
            </ul>
          </div>
          <div>
            <div className="banner-button-large" onClick={() => { window.location.href = "/products/molecular/" }}><div><img src="/images/canva/110.png" alt="" width="20px" height="auto" /> &nbsp;&nbsp;Order from Partners</div></div>
          </div>
        </div>
        <div className="kriya-product-image">
          <img src="/images/canva/102.png" alt="krivida trivus" width="100%" height="auto" />
        </div>
      </div>
      <div className="kriya-product-key-list">
        <div className="kriya-pro-split">
          <div><img src="/images/canva/79.png" alt="" width="90px" height="auto" /></div>
          <div className="content-description content-align-vcenter"><div>The KRIVIDA™ Novus RT PCR Kit is designed with specific primers to target and accurately detect Omicron variants of SARS-CoV-2, ensuring healthcare professionals are equipped with reliable tools</div></div>
        </div>
        <div className="kriya-pro-split">
          <div><img src="/images/canva/80.png" alt="" width="90px" height="auto" /></div>
          <div className="content-description content-align-vcenter"><div>With a testing time of less than 60 minutes, this kit enables faster clinical decision-making, crucial in controlling the spread of the virus. </div></div>
        </div>
        <div className="kriya-pro-split">
          <div><img src="/images/canva/81.png" alt="" width="90px" height="auto" /></div>
          <div className="content-description content-align-vcenter"><div>The kit is designed to provide high accuracy, even with low viral load samples, reducing the chances of false negatives. </div></div>
        </div>
        <div className="kriya-pro-split" style={{ backgroundColor: "#eeeef0" }} onClick={() => { window.location.href = "/products/molecular/" }}>
          <div><div className="content-title hand-cursor" style={{ color: "#914082" }}>Learn more &nbsp;&nbsp; <img src="/images/canva/arrow.png" alt="" width="30px" height="auto" /></div></div>
        </div>
      </div>
    </div>
  );
}


function ProductDiabetes() {
  return (
    <div className="mobile-padding position-relative scroll-prod-diabetes" style={{ backgroundColor: "#914082" }}>
      <div className="kriya-product">
        <div className="px-md-5 kriya-product-content" style={{ color: "#ffffff" }}>
          <div>
            <div><h1 className="content-title" >Optimize Diabetes Management with Kriya's Insulin Syringes</h1></div>
          </div>
          <div className="product-key-list">
            <ul>
              <li><div><img src="images/canva/tick-circle.png" alt="" width="30px" height="30px" />Comfortable and Safe Use</div></li>
              <li><div><img src="images/canva/tick-circle.png" alt="" width="30px" height="30px" />Painless</div></li>
            </ul>
          </div>
          <div>
            <div className="banner-button-large" onClick={() => { window.open("https://www.medikabazaar.com/products/krivida-mefine-u40-insulin-syringe-1ml-mbpgakirraooub39167") }}><div><img src="/images/canva/110.png" alt="" width="20px" height="auto" /> &nbsp;&nbsp;Order from Partners</div></div>
          </div>
        </div>
        <div className="kriya-product-image">
          <img src="/images/canva/104.png" alt="krivida trivus" width="100%" height="auto" />
        </div>
      </div>
      <div className="kriya-product-key-list">
        <div className="kriya-pro-split">
          <div><img src="/images/canva/82.png" alt="" width="90px" height="auto" /></div>
          <div className="content-description content-align-vcenter"><div>Our insulin syringes are designed to deliver precise and accurate doses, ensuring optimal management of blood sugar levels. The ultra-fine needles and ergonomic design prioritize patient comfort, making daily injections less daunting.</div></div>
        </div>
        <div className="kriya-pro-split">
          <div><img src="/images/canva/83.png" alt="" width="90px" height="auto" /></div>
          <div className="content-description content-align-vcenter"><div>Kriya's insulin syringes are compatible with all standard insulin vials, allowing for easy integration into your existing diabetes care regimen. Trusted by healthcare professionals, our syringes offer unmatched reliability and safety.</div></div>
        </div>
        <div className="kriya-pro-split">
          <div><img src="/images/canva/84.png" alt="" width="90px" height="auto" /></div>
          <div className="content-description content-align-vcenter"><div>ICMR Validated Assurance & MDSAP Certification</div></div>
        </div>
        <div className="kriya-pro-split" style={{ backgroundColor: "#eeeef0" }} onClick={() => { window.location.href = "/products/diabetes/" }}>
          <div><div className="content-title hand-cursor" style={{ color: "#914082" }}>Learn more &nbsp;&nbsp; <img src="/images/canva/arrow.png" alt="" width="30px" height="auto" /></div></div>
        </div>
      </div>
    </div>
  );
}


function ProductBiosample() {
  return (
    <div className="mobile-padding position-relative scroll-prod-biosample" style={{ backgroundColor: "#914082" }}>
      <div className="kriya-product">
        <div className="px-md-5 kriya-product-content" style={{ color: "#ffffff" }}>
          <div>
            <div><h1 className="content-title" >Optimize Biosample Collection with Kriya’s Blood Collection Tubes</h1></div>
          </div>
          <div className="product-key-list">
            <ul>
              <li><div><img src="images/canva/tick-circle.png" alt="" width="30px" height="30px" />Precision in Blood Collection</div></li>
              <li><div><img src="images/canva/tick-circle.png" alt="" width="30px" height="30px" />State-of-the-Art Manufacturing</div></li>
              <li><div><img src="images/canva/tick-circle.png" alt="" width="30px" height="30px" />Cost-Effective and Error-Free</div></li>
              <li><div><img src="images/canva/tick-circle.png" alt="" width="30px" height="30px" />Accurate draw volumes and optimized pull force</div></li>
            </ul>
          </div>
          <div>
            <div className="banner-button-large" onClick={() => { window.open("https://www.medikabazaar.com/products/krivida-k2-edta-vacuum-blood-collection-tube-mbpgyyakaeeeob29278") }}><div><img src="/images/canva/110.png" alt="" width="20px" height="auto" /> &nbsp;&nbsp;Order from Partners</div></div>
          </div>
        </div>
        <div className="kriya-product-image">
          <img src="/images/canva/106.png" alt="krivida trivus" width="100%" height="auto" />
        </div>
      </div>
      <div className="kriya-product-key-list">
        <div className="kriya-pro-split-2">
          <div><img src="/images/canva/85.png" alt="" width="90px" height="auto" /></div>
          <div className="content-description content-align-vcenter"><div>Kriya’s blood collection tubes are designed with cutting-edge technology to revolutionize the collection of blood and other body fluids. Our innovative portfolio ensures precision, minimizing pre-analytical errors while reducing costs and turnaround times. With accurate draw volumes and optimized pull force, our tubes are engineered for superior clarity and resistance, even during probe insertion. The vacuum pressures are uniquely calibrated for reliable operation across various altitudes.</div></div>
        </div>
        <div className="kriya-pro-split">
          <div><img src="/images/canva/81.png" alt="" width="90px" height="auto" /></div>
          <div className="content-description content-align-vcenter"><div>ICMR Validated Assurance & MDSAP Certification</div></div>
        </div>
        <div className="kriya-pro-split" style={{ backgroundColor: "#eeeef0" }} onClick={() => { window.location.href = "/products/biosample/" }}>
          <div><div className="content-title hand-cursor" style={{ color: "#914082" }}>Learn more &nbsp;&nbsp; <img src="/images/canva/arrow.png" alt="" width="30px" height="auto" /></div></div>
        </div>
      </div>
    </div>
  );
}


function ProductRespi() {
  return (
    <div className="mobile-padding position-relative scroll-prod-respi" style={{ backgroundColor: "#914082" }}>
      <div className="kriya-product">
        <div className="px-md-5 kriya-product-content" style={{ color: "#ffffff" }}>
          <div>
            <div><h1 className="content-title" >Revolutionize Respiratory Diagnosis with Krivida Trivus Respi Panel - Unparalleled Accuracy, Unmatched Efficiency</h1></div>
          </div>
          <div className="product-key-list">
            <ul>
              <li><div><img src="images/canva/tick-circle.png" alt="" width="30px" height="30px" />Simultaneous Detection of Major Viruses</div></li>
              <li><div><img src="images/canva/tick-circle.png" alt="" width="30px" height="30px" />Rapid Turnaround Time</div></li>
              <li><div><img src="images/canva/tick-circle.png" alt="" width="30px" height="30px" />Cost-Efficient Testing</div></li>
              <li><div><img src="images/canva/tick-circle.png" alt="" width="30px" height="30px" />ICMR Validated Assurance & MDSAP Certification</div></li>
            </ul>
          </div>
          <div>
            <div className="banner-button-large" onClick={() => { window.location.href = "/trivus" }}><div><img src="/images/canva/110.png" alt="" width="20px" height="auto" />  &nbsp;&nbsp;Order from Partners</div></div>
          </div>
        </div>
        <div className="kriya-product-image">
          <img src="/images/canva/108.png" alt="krivida trivus" width="100%" height="auto" />
        </div>
      </div>
      <div className="kriya-product-key-list">
        <div className="kriya-pro-split">
          <div><img src="/images/canva/79.png" alt="" width="90px" height="auto" /></div>
          <div className="content-description content-align-vcenter"><div>RSV, Influenza A & B, and SARS-CoV-2 are detected concurrently, providing a comprehensive view of the patient’s viral landscape</div></div>
        </div>
        <div className="kriya-pro-split">
          <div><img src="/images/canva/80.png" alt="" width="90px" height="auto" /></div>
          <div className="content-description content-align-vcenter"><div>Our kit boasts a rapid turnaround time of less than 30 mins, empowering healthcare professionals with timely and actionable results.</div></div>
        </div>
        <div className="kriya-pro-split">
          <div><img src="/images/canva/81.png" alt="" width="90px" height="auto" /></div>
          <div className="content-description content-align-vcenter"><div>Seamlessly integrate our kit into your existing diagnostic infrastructure, as it is compatible with all available RTPCR instruments.</div></div>
        </div>
        <div className="kriya-pro-split" style={{ backgroundColor: "#eeeef0" }} onClick={() => { window.location.href = "/trivus" }}>
          <div><div className="content-title hand-cursor" style={{ color: "#914082" }}>Learn more &nbsp;&nbsp; <img src="/images/canva/arrow.png" alt="" width="30px" height="auto" /></div></div>
        </div>
      </div>
    </div>
  );
}

function ProductUrine() {
  return (
    <div className="mobile-padding position-relative scroll-prod-urine" style={{ backgroundColor: "#914082" }}>
      <div className="kriya-product">
        <div className="px-md-5 kriya-product-content" style={{ color: "#ffffff" }}>
          <div>
            <div><h1 className="content-title" >Simplify Urine Sample Collection with KRIVIDATM Closed Urine Collection System</h1></div>
          </div>
          <div className="product-key-list">
            <ul>
              <li><div><img src="images/canva/tick-circle.png" alt="" width="30px" height="30px" />Accurate Specimen Collection</div></li>
              <li><div><img src="images/canva/tick-circle.png" alt="" width="30px" height="30px" />Complete Sterility Maintenance</div></li>
              <li><div><img src="images/canva/tick-circle.png" alt="" width="30px" height="30px" />Convenient and Safe Use</div></li>
              <li><div><img src="images/canva/tick-circle.png" alt="" width="30px" height="30px" />Calibrated Vacuum Technology</div></li>
            </ul>
          </div>
          <div>
            <div className="banner-button-large" onClick={() => { window.location.href = "/products/biosample/" }}><div><img src="/images/canva/110.png" alt="" width="20px" height="auto" /> &nbsp;&nbsp;Order from Partners</div></div>
          </div>
        </div>
        <div className="kriya-product-image">
          <img src="/images/canva/109.png" alt="krivida trivus" width="100%" height="auto" />
        </div>
      </div>
      <div className="kriya-product-key-list">
        <div className="kriya-pro-split">
          <div><img src="/images/canva/86.png" alt="" width="90px" height="auto" /></div>
          <div className="content-description content-align-vcenter"><div>The KRIVIDATM Closed Urine Collection System is designed for easy and secure extraction of urine samples.</div></div>
        </div>
        <div className="kriya-pro-split">
          <div><img src="/images/canva/81.png" alt="" width="90px" height="auto" /></div>
          <div className="content-description content-align-vcenter"><div>Our system’s calibrated vacuum technology guarantees precise specimen collection, providing consistent and reliable results. This accuracy is crucial for routine urine analysis and specialized tests, enhancing the overall quality of diagnostics.</div></div>
        </div>
        <div className="kriya-pro-split">
          <div><img src="/images/canva/87.png" alt="" width="90px" height="auto" /></div>
          <div className="content-description content-align-vcenter"><div>ICMR Validated Assurance & MDSAP Certification</div></div>
        </div>
        <div className="kriya-pro-split" style={{ backgroundColor: "#eeeef0" }} onClick={() => { window.location.href = "/products/biosample/" }}>
          <div><div className="content-title hand-cursor" style={{ color: "#914082" }}>Learn more &nbsp;&nbsp; <img src="/images/canva/arrow.png" alt="" width="30px" height="auto" /></div></div>
        </div>
      </div>
    </div>
  );
}
export default ProductExpand;
