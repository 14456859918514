//import React from "react";
import { Container } from "react-bootstrap"; // Row, Col, 

///*"bdadmin@kriyamed.com"*/ 
//content-container
function TrivusAMR() {
  return (
    <div style={{ backgroundColor: "#ffffff" }}>
      <Container className="mobile-padding position-relative ">
        <div className="foo-section" style={{ padding: "80px 0px" }}>
          <div className="foo-section-item">
            <div><p style={{ textTransform: "uppercase" }}>TRIVUS _ KRIYA MEDICAL TECHNOLOGIES</p></div>
            <div><h1 className="sub-title">Combatting Antimicrobial Resistance with the Krivida™ Trivus Respiratory Panel RT-qPCR Kit </h1></div>
            <div className="technologies-align-center">
              <p>The National Medical Commission (NMC) has recently released new guidelines to tackle the growing threat of antimicrobial resistance (AMR). These guidelines strongly recommend avoiding antibiotics until after diagnostic tests have been conducted, ensuring that antibiotics are prescribed only when necessary. The Krivida™ Trivus Respi Panel RT-qPCR Kit emerges as an indispensable tool for healthcare professionals, offering precise and rapid diagnosis of respiratory infections. </p>
            </div>
          </div>
          <div className="foo-section-item">
            <img src="/images/canva/101.png" alt="hand" width="400px" height="auto" />
          </div>
        </div>
        <div>
          <h1 className="sub-title" style={{ color: "#914082" }}>Understanding the New NMC Guidelines</h1>
          <p>The NMC guidelines emphasize the importance of a thorough diagnostic process before prescribing antibiotics. Key steps include:</p>
          <ul>
            <li><b>Accurate History Taking and Clinical Examination:</b> To predict the most likely pathogens.</li>
            <li><b>Collection of Samples Before Starting Antibiotics:</b> Ensuring test results are not influenced by prior antibiotic use.</li>
            <li><b>Experience-Based Antibiotic Therapy:</b> Reserved for critically ill patients when the exact cause of illness is unknown.</li>
          </ul>
          <p>These guidelines, part of the ‘National Action Plan on Antimicrobial Resistance (NAP-AMR) Module for Prescribers,’ stress the necessity of clinical diagnoses to select appropriate tests, aiding in the effective identification of pathogens and optimal antimicrobial use.</p>
          <h1 className="sub-title" style={{ color: "#914082" }}>The Role of the Krivida™ Trivus Respi Panel RT-qPCR Kit</h1>
          <p>The Krivida™ Trivus Respi Panel RT-qPCR Kit is designed to meet the stringent requirements of the NMC guidelines. This innovative diagnostic tool offers several critical features:</p>
          <ul>
            <li><b>Simultaneous Detection of Common Respiratory Viruses:</b> This kit detects RSV, Influenza A & B, and SARS-CoV-2 concurrently, providing a comprehensive view of individual viral infections and co-infections.</li>
            <li><b>Rapid Turnaround Time:</b> Delivers results in less than one hour, which is crucial for initiating timely treatment.</li>
            <li><b>Certified Compliance with Regulatory Standards:</b> The Krivida™ Trivus Respi Panel RT-qPCR Kit meets all necessary regulatory requirements, ensuring compliance with industry standards.</li>
            <li><b>High Accuracy and Efficiency:</b> According to the Indian Council of Medical Research (ICMR) report, the Krivida™ Trivus Respi Panel RT-qPCR Kit demonstrates high sensitivity and specificity, confirming its exceptional diagnostic precision. The kit uses both oropharyngeal and nasopharyngeal swab samples for reliable testing.</li>
            <li><b>Cost-Efficient Testing:</b> Offers a 10x reduction in cumulative testing costs compared to individual tests and a 3x reduction in consumable usage, providing significant economic benefits.</li>
            <li><b>Seamless Integration with Existing Infrastructure:</b>Fully compatible with all RT-PCR instruments, seamlessly integrated into current diagnostic workflows established during the COVID-19 pandemic, enabling the optimal utilization of existing resources.</li>
          </ul>
          <h1 className="sub-title" style={{ color: "#914082" }}>Early Detection and Timely Treatment</h1>
          <p>Early detection of flu, RSV, and COVID-19 is essential for timely treatment. The Krivida™ Trivus Respi Panel RT-qPCR Kit enables healthcare providers to start appropriate treatments promptly. In hospital settings, this test also helps doctors follow infection prevention recommendations accurately, improving patient outcomes.</p>
          <h1 className="sub-title" style={{ color: "#914082" }}>Addressing Antimicrobial Resistance</h1>
          <p>The Krivida™ Trivus Respi Panel RT-qPCR Kit plays a crucial role in:</p>
          <ul>
            <li><b>Reducing Unnecessary Antibiotic Use:</b> By accurately identifying viral infections, it prevents the misuse of antibiotics.</li>
            <li><b>Supporting Empiric Therapy:</b> Provides rapid results that allow for timely review and adjustment of empiric antibiotic therapy for critically ill patients.</li>
          </ul>
          <h1 className="sub-title" style={{ color: "#914082" }}>Economic and Clinical Benefits</h1>
          <h1 className="sub-title" style={{ color: "#914082" }}>Economic Advantages:</h1>
          <ul>
            <li><b>Very Low Cost:</b> Compared to other tests on the market, the Krivida™ Trivus Kit is highly cost-effective.</li>
            <li><b>10x Reduction in Cumulative Testing Costs:</b> Comprehensive testing capabilities offer substantial cost savings.</li>
            <li><b>3x Reduction in Consumable Usage:</b> Lower consumable use translates into reduced operational costs for healthcare facilities.</li>
          </ul>
          <h1 className="sub-title" style={{ color: "#914082" }}>Clinical Advantages:</h1>
          <ul>
            <li><b>3x Reduction in Turnaround Time:</b> Faster results enable quicker clinical decisions, enhancing patient care.</li>
            <li><b>ICMR Validation:</b> The kit is validated by the Indian Council of Medical Research (ICMR), ensuring high standards of quality and reliability.</li>
          </ul>
          <h1 className="sub-title" style={{ color: "#914082" }}>Proudly Made in India</h1>
          <p>The Krivida™ Trivus Respi Panel RT-qPCR Kit is a testament to India’s capability to produce advanced diagnostic solutions. This made-in-India product highlights the nation’s contribution to global healthcare innovations.</p>
          <p>The Krivida™ Trivus Respi Panel RT-qPCR Kit is a revolutionary tool perfectly aligned with the NMC's guidelines to combat AMR. By offering rapid, accurate, and comprehensive diagnostics, it empowers healthcare professionals to make informed decisions, reducing antibiotic misuse and improving patient care. As India continues to combat AMR, the adoption of advanced diagnostic solutions like the Krivida™ Trivus Kit is crucial for safeguarding the efficacy of antibiotics and ensuring better health outcomes.</p>
          <p>Download Brochure Now Discover the full potential of the Krivida™ Trivus Respi Panel RT-qPCR Kit by downloading our comprehensive brochure. [Download Brochure]</p>
          <p>Request a Call Back For personalized assistance and to explore how the Krivida™ Trivus Respi Panel can elevate your respiratory infection diagnostics, request a call back from our experts. [Request a Call Back]</p>
          <p>Invest in Accuracy. Revolutionize Respiratory Tract Infection Diagnosis with the Krivida™ Trivus Respi Panel RT-qPCR Kit. Your patients deserve the best and so do you.</p>
        </div>
      </Container>
    </div>
  );
}
//
export default TrivusAMR;
