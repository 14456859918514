import { Container } from "react-bootstrap"; //Col, Row, 
//className="margin-about-us-title"
/*
   <h3 className="pt-3 sub-title" style={{ color: "#F7CA8E", fontWeight: 700 }}>
                Kriya Medical Technologies
              </h3>
            
*/
function About() {
  return (
    <div className="mobile-padding home-about-holder" style={{ paddingTop: "120px", paddingBottom: "114px" }} id="scroll-me">
      <Container className="position-relative ">
        <div className="home-about">
          <div style={{ marginRight: "0px" }} >
            <div className="pb-1 text-center-aligned">
              <h1 className="pt-2 pb-1 sub-title" style={{ fontWeight: 700, color: "#FFFFFF" }}>
                Our Mission
              </h1>
            </div>
            <div className="text-center-aligned">
              <p className="para" style={{ color: "#FFFFFF", fontWeight: 400 }}>
                ”Touch a Billion Lives” across the globe.
              </p>
            </div>
          </div>
          <div className="margin-about-us" style={{ marginRight: "0px" }}>
            <div className="home-about-top-margin pb-1 text-center-aligned">
              <h1 className="pt-2 pb-1 sub-title" style={{ fontWeight: 700, color: "#914082" }}>
                Vision
              </h1>
            </div>
            <div className="text-center-aligned">
              <p className="para" style={{ color: "#000000" }}>
                Patient-centric solutions enabling labs and hospitals to solve “Cost-Quality-Time” challenges delivering equitable healthcare to underserved populations across the globe
              </p>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}

/**
           <p className="para" style={{ color: "#FFFFFF" }} >
              Kriya’s mission is to transform the detection and treatment of infectious diseases by way of data-led diagnostics and medical devices. Since 2012, our end-to-end solutions and data-driving insights have advanced diagnostics and improved outcomes.
            </p>
            <p className="para" style={{ color: "#FFFFFF" }}>
              Through deep partnerships, diverse perspectives, innovative devices, and digital solutions, Kriya continues to build connections across the continuum of diagnostics.
            </p>
            <p className="para" style={{ color: "#FFFFFF" }}>
              Using novel technologies, state-of-the-art manufacturing facilities, and a quality-by-design approach, Kriya is proud to deliver world-class diagnostics impacting lives globally, every single day.
            </p>
  
 */

export default About;
