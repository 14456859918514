import About from "../components/Homepage/About";
import Banner from "../components/Homepage/Banner";
import KriyaTechnologies from "../components/Homepage/KriyaTechnologies";
import OurProducts from "../components/Homepage/OurProducts";
//import Catalogue from "../components/Homepage/Catalogue";
//import Covid from "../components/Homepage/Covid";
import Global from "../components/Homepage/Global";
//import Partners from "../components/Homepage/Partners";
//import Products from "../components/Homepage/Products";
import ProductExpand from "../components/Homepage/ProductExpand";
//import Services from "../components/Homepage/Services";
import TheMedia from "../components/Homepage/TheMedia";
//import Trivus from "../components/Homepage/Trivus";
import Footer from "./Footer";
import Navigation from "./Navigation";
import { BlogPapers } from "../components/Service/Services";

/**
 style={{
          backgroundImage: `url("/images/homepage/pink-rectangle.png")`,
          backgroundRepeat: "no-repeat",
          width: "100%",
          height: "700px",
          backgroundSize: "cover",
        }}
 */

function Homepage() {
  return (
    <div className="position-relative">
      <Navigation />
      <Banner />
      <About />
      <Global />
      <KriyaTechnologies />
      <OurProducts />
      <ProductExpand />
      <TheMedia />
      <BlogPapers />
      <Footer contact={true} />
    </div>
  );
}
//<Partners />
//<Trivus />
//<Covid />
//<Services />
//<Catalogue />
export default Homepage;
