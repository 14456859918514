import Banner from "../components/Trivus/Banner";
import TrivusContent from "../components/Trivus/TrivusContent";
import Footer from "./Footer";
import Navigation from "./Navigation";

function TrivusDetail() {
  return (
    <div className="position-relative">
      <Navigation />
      <Banner />
      <TrivusContent />
      <Footer contact={false} />
    </div>
  );
}
//<Partners />
//
export default TrivusDetail;
