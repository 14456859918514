import { useState } from "react";
import MDSAPCertified from "../components/Papers/MDSAPCertified";
import RTPCR from "../components/Papers/RTPCR";
import TrivusAMR from "../components/Papers/TrivusAMR";
import Footer from "./Footer";
import Navigation from "./Navigation";
import { Container, Form, FormGroup } from "react-bootstrap";

function ContactPage() {
  return (
    <div>
      <Navigation />
      <div style={{ backgroundColor: "#ffffff" }}>
        <Container>
          <div className="form-holder">
            <div className="contact-form">
              <ContactUsForm />
            </div>
            <div className="contact-form-content">
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div><h1 className="banner-title">Contact Us</h1></div>
                <div><p>Contact us for questions, technical assistance, or collaboration opportunities via the contact information provided.</p></div>
                <div><img src="/images/canva/64.png" alt="" width="30px" height="auto" /> &nbsp;&nbsp;&nbsp;+91 8122994007 </div>
                <div><img src="/images/canva/66.png" alt="" width="30px" height="auto" /> &nbsp;&nbsp;&nbsp;admin@kriyamed.com </div>
                <div><img src="/images/canva/65.png" alt="" width="30px" height="auto" /> &nbsp;&nbsp;&nbsp;B12, SIPCOT Growth Centre, Post, Oragadam Industrial Corridor, Area, Mathur, Tamil Nadu 602105</div>
              </div>
            </div>
          </div>
        </Container>
      </div>
      <Footer contact={false} />
    </div>
  );
};

function BlogTrivus() {
  return (
    <div>
      <Navigation />
      <TrivusAMR />
      <Footer contact={false} />
    </div>
  );
}

function BlogRTPCR() {
  return (
    <div>
      <Navigation />
      <RTPCR />
      <Footer contact={false} />
    </div>
  );
}

function BlogCertification() {
  return (
    <div>
      <Navigation />
      <MDSAPCertified />
      <Footer contact={false} />
    </div>
  );
}


/*interface CResult {
  result: string
};*/

function ContactUsForm() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    company: '',
    message: ''
  });

  const handleChange = (name: string, value: string) => {
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = () => {
    const recipient = "admin@kriyamed.com";
    const subject = `Website Enquiry : From - ${formData.company}`;
    const body = `Hi Kriya Medical Technologies,\n\nPlease find below the enquiry information -\n\nName: ${formData.name} \nEmail: ${formData.email}\nPhone: ${formData.phone}\nCompany: ${formData.company}\n Message: ${formData.message}`;
    const mailtoLink = `mailto:${recipient}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    window.open(mailtoLink);
    //const jsonData = JSON.stringify(formData);
    /*const id = "AKfycbw70i4tcwzWRsDW5Y7BkSBASARX7MM_M5OjGEK9D5MR5z6jxJRP4RystPPdg0aCUUd_";
    const url = `https://script.google.com/a/macros/typetail.com/s/${id}/exec`;
    fetch(url, {
      method: 'POST',
      body: jsonData,
      mode: "no-cors",
      cache: "no-cache",
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Content-Length': `${jsonData.length}`,
        'Host': 'script.google.com'
      },
    }).then((response) => {
      if (!response.ok) {
        console.log("Error");
      }
      response.json().then((value) => {
        const result = value as CResult;
        if (result.result === 'success') {
          console.log("Success");
        } else if (result.result === 'error') {
          console.log("Error");
        }
      });
    }).catch((error) => {
      console.log("Error");
    })*/
    //console.log(jsonData);

    // You can now send jsonData to your server or use it as needed
  };

  return (
    <Form>
      <div style={{ height: "30px" }}></div>
      <FormGroup>
        <Form.Label>Name</Form.Label>
        <Form.Control type="text" placeholder="" name="name"
          value={formData.name}
          onChange={(e) => { handleChange(e.target.name, e.target.value) }} />
      </FormGroup>
      <div style={{ height: "10px" }}></div>
      <FormGroup>
        <Form.Label>Email</Form.Label>
        <Form.Control type="email" placeholder="" name="email"
          value={formData.email}
          onChange={(e) => { handleChange(e.target.name, e.target.value) }} />
      </FormGroup>
      <div style={{ height: "10px" }}></div>
      <FormGroup>
        <Form.Label>Phone</Form.Label>
        <Form.Control type="text" placeholder="" name="phone"
          value={formData.phone}
          onChange={(e) => { handleChange(e.target.name, e.target.value) }} />
      </FormGroup>
      <div style={{ height: "10px" }}></div>
      <FormGroup>
        <Form.Label>Name of the Company</Form.Label>
        <Form.Control type="text" placeholder="" name="company"
          value={formData.company}
          onChange={(e) => { handleChange(e.target.name, e.target.value) }} />
      </FormGroup>
      <div style={{ height: "10px" }}></div>
      <FormGroup>
        <Form.Label>Message</Form.Label>
        <Form.Control as="textarea" rows={3} name="message"
          value={formData.message}
          onChange={(e) => { handleChange(e.target.name, e.target.value) }} />
      </FormGroup>
      <div style={{ height: "20px" }}></div>
      <div><div style={{ padding: "10px 20px", height: "40px", backgroundColor: "#914082", color: "#fff", width: "95px", borderRadius: "10px" }} onClick={() => { handleSubmit() }}><div style={{ textTransform: "uppercase" }}>Submit</div></div></div>
    </Form>
  );
}

export { BlogTrivus, BlogRTPCR, BlogCertification, ContactPage };