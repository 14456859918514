//import React from "react";
import { Container } from "react-bootstrap"; // Row, Col, 

///*"bdadmin@kriyamed.com"*/ 
//content-container
function RTPCR() {
  return (
    <div style={{ backgroundColor: "#ffffff" }}>
      <Container className="mobile-padding position-relative ">
        <div className="foo-section" style={{ padding: "80px 0px" }}>
          <div className="foo-section-item">
            <div><p style={{ textTransform: "uppercase" }}>TRIVUS _ KRIYA MEDICAL TECHNOLOGIES</p></div>
            <div><h1 className="sub-title">A curious case of contamination in RT-PCR!!! </h1></div>
            <div className="technologies-align-center">
              <p>A laboratory validated a new TaqMan Probe based Real Time PCR kit, for simultaneous detection of three respiratory tract infections – SARS CoV2, Influenza and Respiratory Syncytial Virus in nasopharyngeal and oropharyngeal swab samples. The Quality Control department of the laboratory approved the kit, as the kit showed 100% sensitivity and specificity.</p>
              <p>When the Lab In charge checked the findings of the kit validation, she observed an interesting finding in the graphs of negative control  – a small non-sigmoidal signal, crossing the threshold for Internal Control or IC (RNAseP gene) as shown in Image 1. She was concerned about lab contamination (with human sample) and ordered for a complete fumigation, clean room validation and sterilisation of all RTPCR consumables including the RNAse free water.</p>
              <p>Following which she asked to run 10 replicates of the negative control using the same kit but with the freshly sterilised water, tips and tubes. She was surprised to identify similar non sigmoidal signals for not only IC now, but also for SARS CoV2 specific genes in all negative controls, as shown in Image 2. She was worried about this curious source of resistant contamination.</p>
            </div>
          </div>
          <div className="foo-section-item">
            <img src="/images/canva/56.png" alt="hand" width="500px" height="auto" />
          </div>
        </div>
        <div style={{ paddingTop: "40px" }}>
          <h1 className="sub-title" style={{ color: "#914082" }}>Questions in her mind:</h1>


          <h1 className="sub-title" style={{ color: "#914082" }}> 1. What is the source of contamination with human sample and SARS CoV2 virus?</h1>
          <h1 className="sub-title" style={{ color: "#914082" }}>Why is it resistant to all sterilisation procedures?</h1>

          <p>To find an answer, she repeated replicating Negative control samples with another approved kit and found no signal in any of the negative controls. She was relieved to know that the contamination is not in the lab and is in the new kit.</p>
          <p>As a curious lab professional, she did a thorough online search on sources of late appearing non sigmoidal signals in negative control and learnt a lot of interesting facts about RTPCR.</p>
          <p>If you are an equally curious lab professional and want to know the causes of late-appearing non-sigmoidal signals in negative control, click the link below.</p>
          <br /><br />
          <h1 className="sub-title" style={{ color: "#914082" }}>Master Mix + Primer probe Mix + RNAse free water</h1>
          <h1 className="sub-title" style={{ color: "#914082" }}>A human RNA that will be amplified in RTPCR, whenever a human biological specimen is present in the sample</h1>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img src="/images/canva/57.png" alt="" width="80%" height="auto" />
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img src="/images/canva/58.png" alt="" width="80%" height="auto" />
        </div>
        <div>
          <h1 className="title" style={{ color: "#914082" }}>CAUSES OF LATE APPEARING SIGNALS IN REAL TIME PCR</h1>

          <h1 className="sub-title" style={{ color: "#914082" }}>Low positive:</h1>

          <p>If the viral or bacterial load or if the number of copies of RNA or DNA detected is low, a late fluorescent signal can be observed. If such a late appearing signal is seen in negative controls, it can be a low grade contamination of the environment or of any of the components of RT-PCR like water or tips or tubes or master mix or primer probe mix</p>

          <h1 className="sub-title" style={{ color: "#914082" }}>Non-specific amplification:</h1>

          <p>If the primers or probes are not designed properly, they can bind non-specifically to a non-target sequence and can cause a low grade amplification. However, this is applicable only for Real Time PCR based on SYBR chemistry. As Taq Man probe chemistry is very specific, non-specific amplification is never a cause.</p>

          <p>You wonder why?</p>
          <p>Check the box below</p>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img src="/images/canva/59.png" alt="" width="80%" height="auto" />
        </div>
        <div>
          <h1 className="sub-title" style={{ color: "#914082" }}>So Non-specific amplification as the cause of late-appearing signals is excluded.</h1>

          <h1 className="sub-title" style={{ color: "#914082" }}>Probe degradation:</h1>

          <p>This is the solution for this curious case of contamination in RT-PCR:<br />
            As signals are emitted when the reporter dye is detached from the quencher dye, any cause-mediated degradation of the probe can cause signal generation, even in the absence of a target.</p>


          <h1 className="sub-title" style={{ color: "#914082" }}>Causes of Probe degradation:</h1>

          <p>Improper purification of the probe during synthesis<br />
            Improper storage of probes<br />
            Thermocycling conditions, which expose the probe to extremes of temperature for a prolonged period of time<br />
            Presence of exonucleases in the sample or in the form of contamination</p>

          <h1 className="sub-title" style={{ color: "#914082" }}>Impact of probe degradation on RTPCR :</h1>

          <p>The presence of a late-appearing non sigmoidal curve can be misinterpreted as low positive or differentiation low positive samples from negative samples will be a challenge<br />
            The signal can cause a false increase in viral or bacterial load present in the sample</p>

          <h1 className="sub-title" style={{ color: "#914082" }}>TAKE HOME MESSAGE OF THIS CURIOUS CASE:</h1>

          <p>Validation of a Real Time PCR kit should not just include assessment of clinical sensitivity and specificity of the kit. Stringent assessment of the quality of the various components of the kit and thermal conditions of the assay should be carried out by analysing the various aspects of amplification curves of Negative control, positive control and known positive and negative samples.</p>
        </div>
      </Container >
    </div >
  );
}
//
export default RTPCR;
