//import React from "react";
import { Container } from "react-bootstrap"; // Row, Col, 

///*"bdadmin@kriyamed.com"*/ 
//content-container
function KriyaTechnologies() {
  return (
    <div style={{ backgroundColor: "#efefef" }}>
      <Container className="mobile-padding position-relative ">
        <div className="foo-section">
          <div className="foo-section-item">
            <img src="/images/canva/1.png" alt="hand" width="100%" height="auto" />
          </div>
          <div className="foo-section" style={{ paddingTop: "175px", paddingBottom: "175px" }}>
            <div className="technologies-align-center px-5" style={{ height: "100%" }}>
              <p>At Kriya Medical Technologies, being a credible innovator in building patient-centric solutions enables us to develop a global portfolio of products. We are committed to delivering products with the highest level of quality and excellence, governed by stringent regulatory compliance and international standards. This dedication allows us to bridge the gap and make healthcare equitable across geographies, ensuring high-quality solutions for all.</p>
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
}
//
export default KriyaTechnologies;
