import { Container } from "react-bootstrap";
/**
 <Col lg={4} md={4} sm={4} xs={4}>
            <h1 className="pt-5 pb-5" style={{ fontWeight: 700 }}>
              In the Media
            </h1>
          </Col>
 */
//"bg1-white-black" //"bg1-white" //align-media-center
//<span className="logo-action-sub-title">In the media</span>
//className="logo-action-app-link"


function TheMedia() {
  return (
    <div className="my-5" style={{ backgroundColor: "#ffffff" }}>
      <Container className="">
        <div className="footer-content" style={{ gap: "20px" }}>
          <div style={{ flex: 1, justifyContent: "center", height: "400px" }}>
            <div><h1 className="banner-title" style={{ paddingTop: "0" }}>Press Mentions</h1></div>
            <hr style={{ borderTop: "1px solid #914082" }} />
            <div>Stay updated with the latest news and developments at KriyaMed. Browse our press mentions to see how we are making headlines in the medical technology industry. Our image gallery showcases our innovative products, state-of-the-art facilities, and the dedicated teams behind our success.</div>
          </div>
          <div style={{ flex: 2, alignContent: "center", justifyContent: "center" }}>
            <div style={{ padding: "40px", height: "400px", overflowY: "scroll", display: "flex", flexDirection: "column", gap: "80px" }}>
              <div style={{ display: "flex", gap: "80px" }} onClick={() => { window.open("https://www.business-standard.com/article/current-affairs/icmr-approves-new-rt-qpcr-kit-to-detect-influenza-sars-cov-2-rsv-123031600536_1.html") }}>
                <div style={{ color: "#914082" }}><b>2023</b></div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ color: "#914082", textTransform: "uppercase", cursor: "pointer" }}><b>ICMR approves new KRIVIDA TRIVUS to detect influenza, SARS-CoV-2 and RSV</b></div>
                  <div style={{ textTransform: "uppercase" }}><b>Business Standard</b></div>
                  <div><p></p><p></p>ICMR approved a novel RT-qPCR test kit by Chennai-based KRIYA Medical technologies that can detect influenza (H1N1, H3N2, Yamagata and Victoria sublineages), Covid-19, and respiratory syncytial virus (RSV).</div>
                </div>
              </div>
              <div style={{ display: "flex", gap: "80px" }} onClick={() => { window.open("https://www.thehindu.com/news/national/kriya-medical-tech-gets-icmr-approval-for-covid-19-testing-kit/article38296422.ece") }}>
                <div style={{ color: "#914082" }}><b>2022</b></div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ color: "#914082", textTransform: "uppercase", cursor: "pointer" }}><b>Kriya Medical Tech gets ICMR approval for COVID-19 testing kit</b></div>
                  <div style={{ textTransform: "uppercase" }}><b>The Hindu</b></div>
                  <div><p></p><p></p>The Krivida Novus kit will be produced at Kriya's manufacturing facility at Oragadam in Chennai.</div>
                </div>
              </div>
              <div style={{ display: "flex", gap: "80px" }} onClick={() => { window.open("https://yourstory.com/smbstory/chennai-manufacturing-kriya-medical-technologies-covid-19") }}>
                <div style={{ color: "#914082" }}><b>2022</b></div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ color: "#914082", textTransform: "uppercase", cursor: "pointer" }}><b>Making a difference: meet this woman entrepreneur building a multi-crore medical device manufacturing firm</b></div>
                  <div style={{ textTransform: "uppercase" }}><b>YourStory</b></div>
                  <div><p></p><p></p>Kriya was founded in 2012 by Anu Moturi. It manufactures and sells medical devices in the molecular diagnostics, biochemistry, and diabetes categories.</div>
                </div>
              </div>
              <div style={{ display: "flex", gap: "80px" }} onClick={() => { window.open("https://economictimes.indiatimes.com/industry/healthcare/biotech/healthcare/kriya-medical-tech-gets-icmr-approval-for-krivida-novus-covid-19-testing-kit/articleshow/89012655.cms?from=mdr") }}>
                <div style={{ color: "#914082" }}><b>2021</b></div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <div style={{ color: "#914082", textTransform: "uppercase", cursor: "pointer" }}><b>Kriya Medical Technologies gets DCGI nod for RT-PCR kit</b></div>
                  <div style={{ textTransform: "uppercase" }}><b>THE ECONOMIC TIMES</b></div>
                  <div><p></p><p></p>The Krivida Trivus kit will be produced at Kriya's manufacturing facility at Oragadam in Chennai.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-content" style={{ margin: "10px 40px", gap: "40px" }}>
          <div style={{ flex: 1, alignContent: "center", justifyContent: "center" }}>
            <img src="/images/canva/9.png" alt="" width="100%" height="auto" />
          </div>
          <div style={{ flex: 1, alignContent: "center", justifyContent: "center" }}>
            <img src="/images/canva/10.png" alt="" width="100%" height="auto" />
          </div>
          <div style={{ flex: 1, alignContent: "center", justifyContent: "center" }}>
            <img src="/images/canva/11.png" alt="" width="100%" height="auto" />
          </div>
        </div>
      </Container >
    </div >
  );
}

export default TheMedia;

/**
 <Row className="text-center justify-items-center">
          <Col className="text-center">
            <h1 className="pt-3 sub-title">
              In the Media
            </h1>
          </Col>
        </Row>
        <Row className="mob-media-center justify-items-center">
          <Col lg={3} className="mob-media-center">
            <a href="https://www.thehindu.com/news/national/kriya-medical-tech-gets-icmr-approval-for-covid-19-testing-kit/article38296422.ece" target="_blank" rel="noreferrer">
              <img
                src="/images/homepage/hin_logo.png"
                alt="et"
                style={{ cursor: "pointer" }}
              />
            </a>
          </Col>
          <Col lg={3} className="mob-media-center">
            <a href="https://www.financialexpress.com/healthcare/kriya-medical-technologies-gets-dcgi-nod-for-rt-pcr-kit/2433300/" target="_blank" rel="noreferrer">
              <img
                src="/images/homepage/fin_logo.png"
                alt="st"
                style={{ cursor: "pointer" }}
              />
            </a>
          </Col>
        </Row>
 */