//import React from "react";
//import { useState, useCallback, useEffect } from "react";
//import { Container } from "react-bootstrap"; // Row, Col, 

///*"bdadmin@kriyamed.com"*/ 
//content-container
function Banner() {
  return (
    <>
      <style type="text/css">
        {`
      .trivus-banner {
        display: flex;
        align-content: space-between;
        justify-content: space-between;
        height: calc(100vw * 600/1349);
      }
      .trivus-image-src {
        content: url("/images/trivus/desk_banner.png");
      }
      @media screen and (max-width: 1000px) {
        .trivus-banner {
          height: calc(100vw * 600/300);
        }
        .trivus-image-src {
          content: url("/images/trivus/mobile_banner.png");
        }
      }
    `}
      </style>

      <div className="position-relative ">
        <div className="trivus-banner">
          <img
            className="mt-3 position-absolute trivus-image-src"
            src="/images/trivus/desk_banner.png"
            alt="hand"
            width="100%"
            height="auto"
          />
        </div>
      </div>
    </>

  );
}
//
export default Banner;
